import React from "react"

import HeaderOfferings from "../../../components/header_offerings"

export default function MutualFundsAdvisory() {
  const heroTitle = "Mutual Funds Advisory"

  return (
    <HeaderOfferings heroTitle={heroTitle}>
      <h2 class="h2 h2-sm">{heroTitle}</h2>
      <p>
        We assist in constructing an appropriate mutual funds portfolio for you. As part of our
        mutual fund selection process, we employ a comprehensive and rigorous manager search and
        selection methodology that is rational, well-defined, disciplined and consistently applied.
      </p>

      <p>
        Our objective is to identify investment managers that we believe are positioned to
        consistently generate risk-adjusted outperformance for our clients throughout multiple
        market cycles.
      </p>

      <p>
        The mutual fund selection process comprises quantitative and qualitative analysis. We focus
        on consistency of investment process and investment teams at the relevant mutual fund that
        will be managing your hard earned savings. In addition, we help select a combination of
        funds that provide appropriate diversification to manage risk and maximise returns. The list
        is filtered to remove any funds that are too similar, with diversification a key element of
        portfolio construction.
      </p>

      <p>We also focus on executing on our advice in the most cost efficient manner.</p>
    </HeaderOfferings>
  )
}
